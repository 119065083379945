import EarnLinks from "components/earn-links";
import s from "components/earn-links/index.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = useMedia("(max-width: 767px)");

  return (
    <div className={s.container}>
      <EarnLinks />
      <div className={s.content}>
        <div className={s.contentHeader}>
          Raise {isMobile ? <br /> : " - "}
          Online Privacy Policy
        </div>
        <div className={s.subtext}>&nbsp;</div>
        <div className={s.mainContent}>
          This Online Privacy Policy applies to information about you that Raise
          Marketplace, LLC (&quot;Raise,&quot; &quot;we&quot; , &quot;us&quot;
          or “our”) means Raise and it&apos;s affiliates may obtain about you
          when you use raise.com (the &quot;Site&quot;) or our mobile
          application, Raise (the &quot;App&quot;) (collectively, the
          &quot;Services&quot;). This Privacy Policy also describes how we may
          collect, use, or share information about you, including location
          information; your choices regarding our use of your information; the
          ways in which we safeguard the information we collect; and how you may
          contact us regarding our privacy practices.
          <br />
          <br />
          <div className={s.mainContentTopic}>Information We Collect</div>
          <br />
          <div className={s.subContentTopic}>Information You Provide to Us</div>
          <p className={s.pointParagraph}>
            We, or our service providers, may obtain information that you
            provide to us directly through the Services, for example, data you
            submit when you{" "}
            <a href="/user/signup" className={s.link}>
              create an App account
            </a>
            , make a payment on the App, or fill out a form such as a contact
            form. The information we collect may include personal information
            such as your name, email address, phone number, and payment
            information. In some cases we also may request a driver&apos;s
            license, State ID card information, or confirmation of public
            records to authenticate a user&apos;s identity in conjunction with
            certain transactions.
          </p>
          <div className={s.subContentTopic}>
            Information That is Collected Automatically
          </div>
          <p className={s.pointParagraph}>
            We also may automatically collect certain information about you when
            you use the Services. For example, we may use certain automated
            tracking tools to collect your Internet protocol (IP) address,
            device identifiers, device, device OS, advertising identifiers,
            geolocation, user type, browser, device model and details about your
            use of the Services. We may associate the unique identifier assigned
            to your mobile device with other information we obtain about you.
            <br />
            Information about your use of the Services and/or other websites or
            mobile applications may be collected across time, devices, and
            online locations for various purposes, including to associate
            different devices you use with each other and to deliver relevant
            and/or retargeted content to you online. We may combine certain
            automatically-collected information with other information we obtain
            about you.
          </p>
          <p>
            Your web browser may have settings that allow you to transmit a
            &quot;Do Not Track&quot; signal when you visit various websites or
            use online services. Like many websites and online services, the
            Site is not designed to respond to &quot;Do Not Track&quot; signals
            received from browsers. To learn more about &quot;Do Not Track&quot;
            signals, you may wish to visit{" "}
            <a
              href="https://www.allaboutdnt.com"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              https://www.allaboutdnt.com
            </a>
            .
          </p>
          <p>
            To find out more about how tracking technologies providers manage
            the privacy of information in conjunction with delivering targeted
            or behavioral ads online, and how to opt-out of information
            collection by these networks, please visit: youradchoices.com/
            <br />
            appchoices , optout.aboutads.info , or{" "}
            <a
              href="https://optout.networkadvertising.org"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              https://optout.networkadvertising.org
            </a>
            .
          </p>
          <br />
          <div className={s.mainContentTopic}>Data Analytics</div>
          <p className={s.pointParagraph}>
            Raise may use service providers for analytics services, such as
            Google Analytics. These analytics services may use cookies and other
            tracking technologies to help Raise analyze how users use the
            Services. Service providers may store and use information (e.g.,
            your IP address and other usage information) for purposes such as
            evaluating your use of the Services and compiling statistical
            reports on the Services&apos; activity.
          </p>
          <p>
            We&apos;ve implemented Google Analytics Advertising features such as
            dynamic remarketing, interest-based advertising, audience targeting,
            behavioral reporting, demographics and interests reporting, user
            segment analysis, device reporting, display advertising, and video
            ads reporting . We and our vendors may use first-party cookies or
            other first-party identifiers as well as third-party cookies or
            other third-party identifiers to deliver advertisements, create a
            profile of you, measure your interests, detect your demographics,
            detect your location, detect your device, and personalize content. 
          </p>
          <p>
            Behavior Biometrics, are signals relating to an individual&apos;s
            behavior or use of a device access our services, including any user
            behavioral traits, interactions and gestures (e.g., how an
            individual types on a keyboard, moves a mouse, holds a phone or taps
            a touch screen or otherwise interacts with a device). Our Service
            Provider does not collect any data you input on the website page or
            mobile application.
          </p>
          <p>
            To learn more about how Google may use information collected through
            the Services,{" "}
            <a
              href="https://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              click here
            </a>
            . To learn more about opting out of data collection through Google
            Analytics,{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              click here
            </a>
            .
          </p>
          For more information on Google Firebase&apos;s analytics services, you
          may visit their website{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noopener noreferrer"
            className={s.link}
          >
            here.
          </a>
          <br />
          <br />
          <div className={s.mainContentTopic}>Location Information</div>
          <p className={s.pointParagraph}>
            The Services may provide us, and our service providers, precise
            location information so that we can provide and improve our
            location-based products and services. Where available,
            location-based services may use technologies such as GPS (computer
            or mobile device), Bluetooth, crowd-sourced Wi-Fi hotspots, and cell
            tower locations to determine a mobile device&apos;s approximate
            location. You may be able to enable or disable location tracking by
            adjusting the permissions in your device. Be aware that if GPS
            precise location services are disabled, other means of establishing
            or estimating location (e.g., connecting to or proximity to Wi-Fi,
            Bluetooth, beacons, or our networks) may persist. Enabling location
            tracking may enable the Services to track your location in the
            background, which may dramatically decrease battery life. Location
            information may also be subject to the terms and privacy policies of
            our service providers, such as Radar Labs, Inc.
          </p>
          <div className={s.subContentTopic}>App Permissions</div>
          <p className={s.pointParagraph}>
            When you use our App, depending on your device&apos;s or App
            permission settings, we may collect automatically from your device
            the following information:
          </p>
          <ol className={s.numberedList}>
            <li>
              Precise geolocation. This allows the App to collect real-time
              information about the location of your device (GPS and
              network-based) to provide requested location services and to serve
              you relevant offers or promotions.
            </li>
            <li>Camera. This allows the App to take pictures and videos.</li>
            <li>
              Contacts. This allows the App to read your contacts, find accounts
              on the device, and modify your contacts.
            </li>
            <li>
              Stored information and files. This allows the App to read, modify,
              or delete the contents of your USB storage including photographs,
              audio and video clips, personal contacts, and address book
              information.
            </li>
            <li>
              Wi-Fi connection information. This allows the App to view Wi-Fi
              connections.
            </li>
            <li>
              Other. This allows the App to receive data from Internet, view
              network connections, have full network access, control vibration
              of your device, and prevent device from sleeping.
            </li>
            <li>
              SDKs and mobile advertising IDs. Our mobile applications may
              include third-party software development kits (“SDKs”) that allow
              us and our service providers to collect information about your use
              of these applications. In addition, some mobile devices come with
              a resettable identifier (such as Apple&apos;s IDFA and
              Google&apos;s Advertising ID) that, like cookies and pixel tags,
              allow us and our service providers to identify your device over
              time for marketing and/or fraud prevention purposes.
            </li>
          </ol>
          <div className={s.mainContentTopic}>
            How We May Use Your Information
          </div>
          <p className={s.pointParagraph}>
            We may use the information we collect about you for various
            purposes, including to:
          </p>
          <ul className={s.bulletList}>
            <li className={s.bullet}>
              Personalize and improve your experience;
            </li>
            <li className={s.bullet}>
              Respond to emails or other requests, inquiries, comments, or
              questions;
            </li>
            <li className={s.bullet}>Provide customer support;</li>
            <li className={s.bullet}>
              Present you with offers tailored to your interests and past
              activities or usage of the Services;
            </li>
            <li className={s.bullet}>
              Monitor and analyze trends, usage, and activities of visitors to,
              and users of, the Services;
            </li>
            <li className={s.bullet}>
              Improve the Services, our marketing, and products or services we
              may offer;
            </li>
            <li className={s.bullet}>
              Contact you regarding your use of the Services or, in our
              discretion, changes to our policies;
            </li>
            <li className={s.bullet}>
              Verify and/or authenticate your identity including authorized
              devices;
            </li>
            <li className={s.bullet}>
              Provide you with information that we believe may be useful, such
              as information about products or services offered by us or by
              others;
            </li>
            <li className={s.bullet}>
              Prevent fraud or other misuse of the Services, including
              protecting our rights and the rights of affiliates or related
              third parties;
            </li>
            <li className={s.bullet}>
              Resolve disputes, collect fees, or troubleshoot problems;
            </li>
            <li className={s.bullet}>
              Maintain records of our transactions and communications;
            </li>
            <li className={s.bullet}>
              Comply with applicable laws, regulations, or legal process as well
              as industry standards and our company policies;
            </li>
            <li className={s.bullet}>
              Prevent, investigate, identify, or take any other action with
              regard to any suspected or actual fraudulent or illegal activity
              or any activity that violates our Terms of Use; or
            </li>
            <li className={s.bullet}>
              For any other purpose, with your consent.
            </li>
          </ul>
          <p>
            We also may use the information we obtain about you in other ways
            for which we provide notice at the time of collection.
          </p>
          <p>
            We will store your personal information for no longer than is
            necessary for the performance of our obligations or to achieve the
            purposes for which the information was collected, or as may be
            permitted under applicable law. Unless otherwise required by
            applicable law, at the end of the retention period we will remove
            personal information from our systems and records or take
            appropriate steps to properly anonymize it.
          </p>
          <div className={s.mainContentTopic}>How We May Share Information</div>
          <p className={s.pointParagraph}>
            We may permit our agents, 3rd party vendors, consultants, and other
            service providers to access information we collect about you through
            the Services to carry out work on our behalf. For example, we may
            use outside companies to help us send emails or text messages and
            manage email or text message campaigns, or host or operate the
            Services. Service providers use the personal information to which
            they have access to carry out the purposes for which we shared the
            information.
          </p>
          <p>
            We also may share your information: (1) if we are required to do so
            by law, regulation, or legal process (such as in response to a court
            order or subpoena or similar government request for information);
            (2) to fulfill requests by government agencies, including law
            enforcement authorities; regulatory agencies, (3) when we believe
            disclosure is necessary or appropriate to prevent physical harm or
            financial loss, or in connection with an investigation of suspected
            or actual illegal activity; (4) to enforce our policies, or to
            protect legal rights, property, or safety; (5) with third parties,
            to investigate or address possible criminal or fraudulent activity;
            including fraud prevention and fraud detection purposes, or (6) in
            the event of a joint venture, partnership, merger, or other
            collaboration with another organization.
          </p>
          <p>
            We reserve the right to transfer any information we obtain through
            the Services in the event we sell or transfer all or a portion of
            our business or assets (e.g., further to a merger, reorganization,
            liquidation, or any other business transaction, including
            negotiations of such transactions).
          </p>
          <div className={s.mainContentTopic}>Information Security</div>
          <p className={s.pointParagraph}>
            We maintain appropriate administrative, technical, and physical
            safeguards designed to help protect personal information collected
            or received through the Services. Although we use reasonable efforts
            to safeguard information, transmission via the Internet is not
            completely secure and we cannot guarantee the security of your
            information collected through the Services. You accept the inherent
            security risks of providing information and dealing online over the
            Internet and will not hold us responsible for any breach of security
            unless this is due to our negligence.
          </p>
          <div className={s.mainContentTopic}>
            Links to Other Online Services
          </div>
          <p className={s.pointParagraph}>
            The Services may include links to other websites, apps or features
            for your convenience and information. Websites, apps or features
            that are operated by entities not affiliated with Raise may have
            their own privacy policies or notices. Raise is not responsible for
            the content or privacy practices of any linked websites, apps, or
            features that we do not control.
          </p>
          <div className={s.mainContentTopic}>Social Features</div>
          <p className={s.pointParagraph}>
            The Services may include features that are designed to permit
            interactions that you initiate between the Services and third-party
            websites or services, including third-party social networks
            (&quot;Social Features&quot;). Examples of Social Features include
            enabling you to &quot;like&quot; or &quot;share&quot; our content on
            other websites or services.
          </p>
          <p>
            In some cases, you may use Social Features in relation to a referral
            program (for example, you can earn a reward if the recipient you
            send a referral to chooses to register for one of our offering).
            Such offers are subject to their additional terms and policies. If
            you provide us personal information about another person, you
            represent that you have the authorization to do so.
          </p>
          <p>
            If you use Social Features on the Services, both Raise and the
            third-party service that operates the Social Features may have
            access to certain information about you and your use of both the
            Services and theirs. The information we collect in connection with
            Social Features is subject to this Privacy Policy. The information
            collected and stored by the third parties remains subject to those
            third parties&apos; privacy practices, including whether the third
            parties continue to share information with us, the types of
            information shared, and your choices with regard to what is visible
            to others on those third-party websites or services.
          </p>
          <div className={s.mainContentTopic}>Children&apos;s Privacy</div>
          <p className={s.pointParagraph}>
            The Services are not intended for, nor targeted to, children under
            13, and we do not knowingly or intentionally collect information
            from children under 13. If we learn that we have received
            information directly from a child who is under the age of 13, we
            will delete the information in accordance with applicable law.
          </p>
          <div className={s.mainContentTopic}>California Privacy Rights</div>
          <p className={s.pointParagraph}>
            California&apos;s &quot;Shine the Light&quot; law permits customers
            in California to request certain details about how their
            &quot;personal information&quot; (as defined by California Civil
            Code Section 1798.83) is shared with third parties for direct
            marketing purposes. Under the law, a business must either provide
            this information or permit California customers to opt in to, or opt
            out of, this type of sharing.
          </p>
          <p>
            California residents may have certain rights under the California
            Consumer Privacy Act, as set forth in our California Consumer
            Privacy Notice{" "}
            <a
              href="https://gcx.raise.com/assets/raise-ccpa-notice-12-31-2019.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              here
            </a>
            .
          </p>

          <div className={s.mainContentTopic}>Additional Information
            Relating to Users Located in the UK and Canada</div>    
          <p className={s.pointParagraph}>
            If you are located within the UK (United Kingdom) or Canada,
            the following information applies to you:
          </p>
          <ul className={s.bulletList}>
            <li className={s.bullet}>
              When we collect your personal information, 
              we act as the data controller in compliance with applicable
              UK and Canadian privacy regulations such as (PIPA) 
              The Personal Information Protection Act and The UK Data 
              Protection Act 2018.
            </li>
            <li className={s.bullet}>
              Unless we inform you otherwise, your personal data will 
              not be used for any automated decision-making 
              processes that may affect you. 
            </li>
            <li className={s.bullet}>
              When we collect your personal information, we will inform 
              you if there is a legal or contractual obligation
              to disclose it. 
            </li>
            <li className={s.bullet}>
              If you have a complaint about our use of personal information,
              you have a right to lodge a complaint with the an applicable
              data protection authority.
            </li>
          </ul>
            <div className={s.pointParagraph}>
              <strong>Pursuant to privacy laws, you are entitled to:</strong>
            </div>
          <ul className={s.bulletList}>
            <li className={s.bullet}>
              <strong>Request Information:</strong> Inquire about the personal
              data we hold on you.
            </li>
            <li className={s.bullet}>
              <strong>Update your Data:</strong> Request corrections to
              any inaccurate personal information.
            </li>
            <li className={s.bullet}>
              <strong>Delete Your Data:</strong> Ask us to erase your personal
              information from our systems.
            </li>
            <li className={s.bullet}>
              <strong>Control Data Processing:</strong> Under certain conditions,
              you can object to or restrict how we collect and process
              your personal information.
            </li>
          </ul>

          <div className={s.mainContentTopic}>Your Choices</div>
          <p className={s.pointParagraph}>
            You may unsubscribe from receiving promotional emails from us by
            following the instructions provided in those email communications.
            Please note that even if you opt out of receiving promotional
            communications from us, we may continue to send you non-promotional
            emails, such as communications regarding our ongoing relationship
            with you.
          </p>
          <p>
            You can prevent further collection of information via the App by
            uninstalling the App. Also, you may be able to exercise specific
            privacy choices, such as enabling or disabling certain features
            (e.g., camera access, push notifications, or access to your photos),
            by adjusting the permissions on your mobile device or through the
            App&apos;s settings.
          </p>
          <p>
            If you wish to update, correct, or delete information you have
            provided through the App, you may edit certain information by
            signing into your App account.
          </p>
          <div className={s.mainContentTopic}>
            Information for Users Outside the United States, United Kingdom,
            and Canada
          </div>
          <p className={s.pointParagraph}>
            As set forth in our{" "}
            <Link to="/earn/terms" className={s.link}>
              Terms of Use
            </Link>
            , the Services are intended for use in the United States,
            United Kingdom, and Canada. If you happen to be visiting 
            us from outside the United States, please be aware that 
            information we collect will be transferred to and stored
            on our servers in the United States. By using the Services, you
            acknowledge and consent to the transfer and processing of your
            personal data in the United States as described in this Privacy
            Policy. Please be aware that the data protection laws and
            regulations applicable to your personal data transferred to the
            United States may be different from the laws in your country of
            residence.
          </p>
          <div className={s.mainContentTopic}>
            Changes to this Privacy Policy
          </div>
          <p className={s.pointParagraph}>
            This Privacy Policy may be updated periodically to reflect changes
            to our privacy practices. Any changes we make to this Privacy Policy
            will be effective when we post the revised policy on our Website. We
            strongly encourage you to refer to this Privacy Policy often for the
            latest information about our privacy practices. By continuing to
            interact with us after the modified version of the policy has been
            posted, you are accepting the changes to the policy.
          </p>
          <div className={s.mainContentTopic}>How to Contact Us</div>
          <p className={s.pointParagraph}>
            If you have questions regarding this Privacy Policy you may email us
            at{" "}
            <a href="mailto:legal@raise.com" className={s.link}>
              legal@raise.com
            </a>{" "}
            or write to us at:
          </p>
          Raise Marketplace, LLC
          <br />
          Attention: Privacy Inquiry
          <br />
          2045 W Grand Ave Ste B #61404
          <br />
          Chicago, Illinois 60612
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
