export const PRESS_RELEASE_ITEMS = [
  {
    title:
      "Raise Launches Mobile App for Consumers and API Solutions for Businesses in Canada as Part of Global Expansion",
    subtitle: "Raise Team | Nov 20, 2024",
    content:
      "Raise, the leader in gift card innovation, today announced its expansion into Canada, marking its second significant international milestone, following the successful launch in the United Kingdom earlier this month.  ",
    callToAction: "https://www.prnewswire.com/news-releases/raise-launches-mobile-app-for-consumers-and-api-solutions-for-businesses-in-canada-as-part-of-global-expansion-302310900.html",
  },
  {
    title:
      "Raise Brings Leading Gift Card Platform to Europe with United Kingdom Expansion",
    subtitle: "Raise Team | Nov 14, 2024",
    content:
      "Raise, the world’s largest gift card exchange and retailer, today announced its expansion into the United Kingdom marking the company’s first major international venture outside of the United States.",
    callToAction: "https://www.prnewswire.co.uk/news-releases/raise-brings-leading-gift-card-platform-to-europe-with-united-kingdom-expansion-302304885.html#:~:text=With%20Raise's%20expansion%20into%20the,on%20any%20gift%20card%20purchase",
  },
  {
    title: "Gift Card Leader Raise Brings Crypto Payments to Mainstream Retail",
    subtitle: "Raise Team | Aug 15, 2024",
    content:
      "Raise, a leading payments provider and innovator in the global gift card industry, today announced the acceptance of crypto as a form of payment in the Raise App",
    callToAction: "https://www.prnewswire.com/news-releases/gift-card-leader-raise-brings-crypto-payments-to-mainstream-retail-302223750.html?tc=eml_cleartime",
  },
  {
    title: "Raise Announced as Payments Provider for the Polkadot Mobile App",
    subtitle: "Raise Team | Jul 18, 2024",
    content:
      "Raise's payment infrastructure enables $DOT payments at over one million stores and websites",
    callToAction: "https://www.prnewswire.com/news-releases/raise-announced-as-payments-provider-for-the-polkadot-mobile-app-302200024.html",
  },
];
