import React from "react";
import "./style.scss";
import breadcrumbChevron from "assets/images/breadcrumb-chevron.svg";
import heroMobile from "assets/images/press-hero-mobile.png";
import heroTablet from "assets/images/press-hero-tablet.png";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { PRESS_RELEASE_ITEMS } from "./utils/press-release-items";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="breadcrumb-container">
        <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
          Home
        </Link>
        <img
          src={breadcrumbChevron}
          alt="chevron"
          className="breadcrumb-chevron"
        />
        <span className="mint-green">Press</span>
      </div>
      <div className="hero-title">Raise Press Hub</div>
      <div className="hero-subtext">
        Read the latest press releases and news updates about Raise. Stay
        connected with our latest company activities and achievements.
      </div>
      <div className="hero-image-mobile">
        <img src={heroMobile} alt="hero-mobile" className="hero-mobile" />
      </div>
      <img src={heroTablet} alt="hero-tablet" className="hero-tablet" />
    </div>
  );
};

const Connect = () => {
  const { width } = useWindowDimensions();
  const isMobile = !!(width < 768);
  return (
    <div className="connect-container">
      <div className="connect-title">
        Want to connect? {isMobile && <br />} Write to us.
      </div>
      <div className="connect-mails">
        <div className="contact-card">
          <div className="label">Contact</div>
          <a href="mailto:press@raise.com" className="mail-to">press@raise.com</a>
        </div>
      </div>
    </div>
  );
};

const PressReleasePosts = () => {
  return (
    <div className="releases-container">
      {PRESS_RELEASE_ITEMS.map((item, index) => (
        <div className="card" key={index}>
          <div className="card-title">{item.title}</div>
          <div className="card-subtitle">{item.subtitle}</div>
          <div className="card-content">{item.content}</div>
          <a
            href={item.callToAction}
            className="card-cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
        </div>
      ))}
    </div>
  );
};

export const Press = () => {
  return (
    <div className="container">
      <Hero />
      <Connect />
      <PressReleasePosts />
    </div>
  );
};
